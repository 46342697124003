import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomRegisterCard from "../../components/cards/CustomRegisterCard";
import { isAuthenticated } from "../../services/auth";
import Topbar from "../../layout/Topbar";

const ParentRegister = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <>
      <Topbar />
      <div className="background-image">
        <div className="flex justify-content-center align-items-center w-full h-100-top">
          <CustomRegisterCard
            heading={"ADULT REGISTRATION"}
            subHeading={
              "Register with RAD RUN CLUB so you can create FREE child profiles and have your school or sporting club added as a RAD RUN CLUB venue."
            }
            checkbox
          ></CustomRegisterCard>
        </div>
      </div>
    </>
  );
};

export default ParentRegister;
