import React, { useEffect, useState } from "react";
import {
  CustomCalenderInput,
  CustomDropDown,
  CustomFloatInput,
  CustomFloatInputNumber,
  CustomMultiSelect,
} from "../../components/input/input";
import { useDispatch, useSelector } from "react-redux";
import UserAddCard from "../../components/cards/UserAddCard";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import {
  addChild,
  fetchClubs,
  fetchSchools,
  getClassesBySchool,
  getHousesBySchool,
  getTeamsByClub,
  updateChildProfile,
} from "../../redux1/Slices/parentSlice";
import { State, City } from "country-state-city";
import { DashboardFun } from "./DashboardFun";
import Loader from "../../utils/Loader";

export const AddApplicants = ({
  data,
  setData,
  visible,
  setVisible,
  setSearch,
}) => {
  const dispatch = useDispatch();
  const { childIdentify, Gender } = DashboardFun();

  const userProfile = useSelector((state) => state.user.data);
  const uniqueID = useSelector((state) => state.children.uniqueId);
  const TeacherRole = userProfile?.role === "TEACHER";
  const EventOrgnizerRole = userProfile?.role === "ORGANIZER";
  const [, setState] = useState();
  const [, setCity] = useState();
  const [classes, setClasses] = useState([]);
  const [houses, setHouses] = useState([]);
  const [teams, setTeams] = useState([]);

  const clubs = useSelector((state) => state.children.clubs);
  const schools = useSelector((state) => state.children.schools);
  const ignore = ["school", "club", "class", "house", "team"];

  useEffect(() => {
    if (data.school) {
      dispatch(
        getClassesBySchool({
          clubId: data.school,
          setData: setClasses,
        })
      );
      dispatch(
        getHousesBySchool({
          clubId: data.school,
          setData: setHouses,
        })
      );
    }
    if (data.club) {
      dispatch(
        getTeamsByClub({
          clubId: data.club,
          setData: setTeams,
        })
      );
    }
  }, [data.school, data.club]);

  useEffect(() => {
    if (visible) {
      dispatch(fetchClubs({}));
      dispatch(fetchSchools({}));
    }
  }, [visible]);

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data, ignore);
    if (name === "country") {
      setData({ ...data, [name]: value.name });
      setState(State.getStatesOfCountry(value.isoCode));
    }
    if (name === "state") {
      setData({ ...data, [name]: value.name });
      setCity(City.getCitiesOfState(value.countryCode, value.isoCode));
    }

    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const submitForm = () => {
    const reducedData = Object.entries(data).reduce((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    let payload = {
      ...reducedData,
      radRunIdentifier: uniqueID,
    };
    let editPayLoad = {
      ...reducedData,
    };

    if (showFormErrors(data, setData, ignore)) {
      if (!data.id) {
        dispatch(addChild({ payload, setVisible, setData }));
        setSearch("");
      } else {
        dispatch(updateChildProfile({ payload: editPayLoad, setVisible }));
        setSearch("");
      }
    }
  };

  return (
    <div>
      <Loader />
      <UserAddCard
        visible={visible}
        data={data}
        extraClass={"w-10 p-2 overflow-y-auto"}
        setVisible={setVisible}
        closePopup={() => {
          setVisible(false);
          setData({
            firstName: "",
            lastName: "",
            school: "",
            club: "",
            gender: "",
            class: "",
            house: "",
            team: "",
            dob: "",
            childIdentifiedAsIslander: null,
            hasDisabilityOrLongTermHealthCondition: null,
            howManyDaysChildWasPhysicallyActive: null,
            role: "CHILD",
          });
        }}
        save={() => {
          submitForm();
        }}
        heading={
          TeacherRole
            ? "Student"
            : EventOrgnizerRole
            ? "Request RAD RUN"
            : "Child"
        }
        SubHeading={
          TeacherRole
            ? "Please take a moment to fill in your student details."
            : EventOrgnizerRole
            ? ""
            : "Please take a moment to fill in your child details."
        }
        identifier={uniqueID}
        on
      >
        <CustomFloatInput
          onChange={handleChange}
          value={data?.firstName}
          name={"firstName"}
          data={data}
          col={6}
          label={"First Name"}
        ></CustomFloatInput>
        <CustomFloatInput
          onChange={handleChange}
          value={data?.lastName}
          name={"lastName"}
          data={data}
          col={6}
          label={"Last Name"}
        ></CustomFloatInput>
        <CustomDropDown
          col={6}
          options={Gender}
          value={data?.gender}
          name={"gender"}
          data={data}
          onChange={handleChange}
          optionLabel="name"
          optionValue="value"
          label={"Gender"}
        ></CustomDropDown>
        <CustomCalenderInput
          col={6}
          value={data?.dob ? new Date(data?.dob) : ""}
          name={"dob"}
          data={data}
          maxDate={new Date()}
          onChange={handleChange}
          label={"Date of Birth"}
        ></CustomCalenderInput>{" "}
        <CustomDropDown
          col={6}
          options={schools}
          optionLabel="name"
          optionValue="_id"
          value={data?.school}
          name="school"
          data={data}
          label={"School"}
          onChange={handleChange}
        ></CustomDropDown>
        <CustomMultiSelect
          col={3}
          options={classes}
          optionLabel="name"
          optionValue="_id"
          value={data?.class}
          name="class"
          data={data}
          label={"Class"}
          onChange={handleChange}
        ></CustomMultiSelect>
        <CustomMultiSelect
          col={3}
          options={houses}
          optionLabel="name"
          optionValue="_id"
          value={data?.house}
          name="house"
          data={data}
          label={"House"}
          onChange={handleChange}
        ></CustomMultiSelect>
        <CustomDropDown
          col={6}
          options={clubs}
          optionLabel="name"
          optionValue="_id"
          value={data?.club}
          name="club"
          data={data}
          label={"Club"}
          onChange={handleChange}
        ></CustomDropDown>
        <CustomMultiSelect
          col={6}
          options={teams}
          optionLabel="name"
          optionValue="_id"
          value={data?.team}
          name="team"
          data={data}
          label={"Team"}
          onChange={handleChange}
        ></CustomMultiSelect>
        <CustomDropDown
          col={12}
          name={"childIdentifiedAsIslander"}
          data={data}
          value={data?.childIdentifiedAsIslander}
          options={childIdentify}
          onChange={handleChange}
          showFilter={false}
          optionLabel=""
          label={
            "Is this child of Aboriginal and/or Torres Strait Islander origin?"
          }
        ></CustomDropDown>
        <CustomDropDown
          col={12}
          name={"hasDisabilityOrLongTermHealthCondition"}
          data={data}
          showFilter={false}
          value={data?.hasDisabilityOrLongTermHealthCondition}
          options={childIdentify}
          onChange={handleChange}
          optionLabel=""
          label={
            "Does this child have a disability or long-term health condition?"
          }
        ></CustomDropDown>
        <CustomFloatInputNumber
          col={12}
          name={"howManyDaysChildWasPhysicallyActive"}
          extraClassName={"mb-0"}
          data={data}
          value={data?.howManyDaysChildWasPhysicallyActive}
          onChange={handleChange}
          optionLabel=""
          maxValue={7}
          maxLength={1}
          label={
            <span>
              On how many days in the past 7 days was this child physically
              active for a total of at least 60 minutes at moderate or vigorous
              physical activity levels*?
            </span>
          }
        ></CustomFloatInputNumber>
        <span className="text-xs text-600 mb-3 ml-2">
          *Moderate to vigorous physical activity is any activity that increases
          your child’s heart rate and makes them get out of breath some of the
          time, such as cycling, running or playing sports.
        </span>
        {/* </>
        )} */}
      </UserAddCard>
    </div>
  );
};
